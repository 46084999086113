<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title>
        {{ isUpdating ? "Editar " : "Cadastrar" }} Agenda
      </v-card-title>
      <v-card-text v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="secondary"
        ></v-progress-circular>
      </v-card-text>
      <v-card-text v-show="!loading">
        <v-form ref="form">
          <div class="row col-xs">
            <div class="col">
              <v-text-field
                v-model="eventForm.name"
                :rules="[rules.required]"
                label="Titulo"
                outlined
                dense
                hide-details="auto"
              />
            </div>
            <div class="col-6">
              <date-picker
                ref="datePickerRef"
                v-model="formDates"
                :range="true"
              ></date-picker>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col">
              <v-autocomplete
                v-model="eventForm.category"
                label="Categoria"
                dense
                outlined
                :items="categories"
                item-text="text"
                item-value="value"
                hide-details="auto"
              />
            </div>
          </div>
          <div class="row col-xs">
            <div class="col">
              <v-textarea
                v-model="eventForm.description"
                label="Descrição"
                dense
                outlined
                hide-details="auto"
              >
              </v-textarea>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col">
              <v-text-field
                v-model="eventForm.url"
                label="URL"
                dense
                outlined
              />
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="close" class="ma-1 text-capitalize">
          Voltar
        </v-btn>
        <v-btn
          color="error"
          v-if="isUpdating"
          @click="cancelEvent"
          class="ma-1 text-capitalize"
        >
          <v-icon class="ma-2">
            {{ icons.mdiDelete }}
          </v-icon>
          Cancelar Evento
        </v-btn>
        <v-btn color="success" @click="submitForm" class="ma-1 text-capitalize">
          <v-icon class="ma-2">
            {{ icons.mdiContentSave }}
          </v-icon>
          Salvar Evento
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiContentSave, mdiDelete } from "@mdi/js";

import datePicker from "@/components/app/ui/datePicker.vue";
import formMixin from "@/util/mixins/formMixin";

export default {
  name: "CalendarEventForm",

  components: {
    datePicker,
  },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      menuDataStart: false,
      menuDataEnd: false,
      loading: false,
      isUpdating: false,

      formDates: null,

      eventForm: {
        name: null,
        start: null,
        end: null,
        category: null,
        description: null,
        status: 0,
        url: null,
      },

      categories: [
        { text: "Vermelho", value: "#FE5F55" },
        { text: "Amarelo", value: "#e4ce01" },
        { text: "Verde", value: "#6FFE52" },
        { text: "Azul", value: "#52EAFE" },
        { text: "Purpura", value: "#A352FE" },
      ],

      icons: {
        mdiContentSave,
        mdiDelete,
      },
    };
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.reset();
        this.$refs.datePickerRef.reset();
      }
    },
    formDates() {
      this.eventForm.start = this.formDates[0];
      this.eventForm.end = this.formDates[1]
        ? this.formDates[1]
        : this.formDates[0];
      console.log(this.eventForm);
    },
  },

  methods: {
    open(id) {
      this.dialog = true;
      this.isUpdating = id != null;

      if (this.isUpdating) {
        this.getCalendarEvent(id);
      }
    },

    getCalendarEvent(id) {
      this.loading = true;
      this.$http.$get(`/calendar/${id}`).then((response) => {
        this.eventForm = response.data;
        this.$refs.datePickerRef.reset([
          this.eventForm.start,
          this.eventForm.end,
        ]);
        this.loading = false;
      });
    },

    close() {
      this.dialog = false;
    },

    submitForm() {
      if (this.$refs.form.validate()) {
        if (this.isUpdating) {
          this.$http
            .$put(`/calendar/${this.eventForm.id}`, this.eventForm)
            .then((response) => {
              this.dialog = false;
            });
        } else {
          this.$http.$post("/calendar", this.eventForm).then((response) => {
            this.dialog = false;
          });
        }
        this.$emit("change");
      }
    },

    cancelEvent() {
      this.$http.destroy(`calendar/${this.eventForm.id}`)
      .then((response) => {
        this.$emit("change");
        this.dialog = false;
      })
      .catch((error) => {
        console.log(error);
      })
    },
  },
};
</script>
<template>
    <div>
        <h1 class="ma-2 ml-0">
            Agenda
        </h1>
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="move(-1)">
                        <v-icon>
                            {{ icons.mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-progress-circular v-if="loading" indeterminate color="secondary"></v-progress-circular>
                    <span v-else>
                        {{ showDate }}
                    </span>
                    <v-btn icon @click="move(1)">
                        <v-icon>
                            {{ icons.mdiChevronRight }}
                        </v-icon>
                    </v-btn>
                    <v-btn color="success" @click="newEvent()" class="text-capitalize">
                        <v-icon>
                            {{ icons.mdiPlus }}
                        </v-icon>
                        Novo Evento
                    </v-btn>
                </v-card-title>
                <v-sheet height="450">
                    <div v-if="loading" class="d-flex justify-center">
                        <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                    </div>
                    <v-calendar
                        v-if="!loading"
                        v-model="step"
                        ref="calendar"
                        type="month"
                        locale="PT-br"
                        :events='events'
                        :event-color="event => event.color"
                        @change="e => setShowDate(e)"
                        @click:event="editEvent"
                    >
                        <template v-slot:event="{ event }">
                            <div :style="{backgroundColor:event.color}">
                                <span style="font-size: large;" class="ma-2">
                                    {{ event.name }}
                                </span>
                            </div>
                        </template>
                    </v-calendar>
                </v-sheet>
            </v-card-text>
        </v-card>
        <calendar-event-form ref="form" @change="getEvents()"></calendar-event-form>
    </div>
</template>

<script>
import CalendarEventForm from '../../components/calendar/form/CalendarEventForm.vue'
import { mdiChevronRight, mdiChevronLeft, mdiPlus } from '@mdi/js'

let months = ['0', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

export default {
    name: 'CalendarScreen',

    components: {
        CalendarEventForm
    },
    
    data(){
        return {
            loading: false,
            step: null,
            showDate: null,

            icons: {
                mdiPlus,
                mdiChevronRight,
                mdiChevronLeft
            },
            events:[],
        }
    },

    mounted(){
        this.getEvents();
    },

    methods:{
        getEvents(){
            this.loading = true
            this.$http.index('/calendar').then( response => {
                this.events = response.data.data.map(event => {
                    return {
                        id: event.id,
                        name: event.name,
                        start: event.start,
                        end: event.end,
                        color: event.category,
                        description: event.description,
                        url: event.url
                    }
                })
                this.loading = false;
            })
        },
        newEvent(){
            this.$refs.form.open();
        },

        move(step){
            this.$refs.calendar.move(step);
        },

        editEvent(event){
            this.$refs.form.open(event.event.id);
        },
        setShowDate(e){
            this.showDate = months[e.start.month] + ' de ' + e.start.year;
        },
    },
    
}
</script>